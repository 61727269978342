<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <a-input
        style="width: 300px"
        placeholder="Cari ID Transaksi"
        v-model:value="search"
        @change="onSearch"
      >
        <template #prefix>
          <span>
            <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
          </span>
        </template>
      </a-input>
    </div>
    <div>
      <a-table :columns="columns" :data-source="dataTable" :loading="loadingTable" bordered>
        <template #createdAt="{ text, record }">
          <div class="d-flex flex-column">
            <a-tag class="align-self-start" v-if="record.status === 'success'" color="success">
              <template #icon>
                <check-circle-outlined />
              </template>
              Berhasil
            </a-tag>
            <a-tag class="align-self-start" v-if="record.status === 'pending'" color="warning">
              <template #icon>
                <clock-circle-outlined />
              </template>
              Menunggu Pembayaran
            </a-tag>
            <a-tag class="align-self-start" v-if="record.status === 'cancel'" color="error">
              <template #icon>
                <close-circle-outlined />
              </template>
              Dibatalkan
            </a-tag>
            <span class="mt-2">{{ text }}</span>
            <span class="mt-2">{{ record.external_id }}</span>
          </div>
        </template>
        <template #totalPrice="{ text }">
          {{ priceFormat(text) }}
        </template>
        <template #action="{ record }">
          <button
            :disabled="record.status !== 'pending'"
            @click.prevent="onUpdateStatus(record)"
            class="btn btn-outline-primary"
          >
            Perbaharui Status
          </button>
        </template>
        <template #expandedRowRender="{ record }">
          <a-table
            :columns="innerColumns"
            :data-source="record.transactionDetails"
            :pagination="false"
          >
            <template #coursePrice="{ text }">
              <div v-if="record.discountPercentage" class="d-flex flex-column">
                <del class="mr-2"
                  ><small>{{ priceFormat(text) }}</small></del
                >
                <span>{{ priceFormat(recordPrice.discountedPrice) }}</span>
              </div>
              <span v-else>{{ priceFormat(text) }}</span>
            </template>
          </a-table>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import priceFormat from '@/helpers/rupiahPrice';
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined , CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue';

const columns = [
  {
    title: 'Tanggal Transaksi',
    dataIndex: 'createdAt',
    key: 'createdAt',
    slots: { customRender: 'createdAt' },
  },
  {
    title: 'Jumlah Course',
    dataIndex: 'totalCourse',
    key: 'totalCourse',
    slots: { customRender: 'totalCourse' },
    align: 'center',
  },
  {
    title: 'Total Biaya',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    slots: { customRender: 'totalPrice' },
    align: 'right',
  },
  {
    title: 'Aksi',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];
const innerColumns = [
  {
    title: 'Course',
    dataIndex: 'courseName',
    key: 'courseName',
    slots: { customRender: 'courseName' },
  },
  {
    title: 'Harga',
    dataIndex: 'coursePrice',
    key: 'coursePrice',
    slots: { customRender: 'coursePrice' },
  },
];
export default {
  components: {
    SearchOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    // PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
  },
  props: {
    userId: {
      type: String,
      default: () => { return '' },
    },
  },
  setup(props) {
    const store = useStore()
    let dataTable = ref([])
    let loadingTable = ref(false)
    let search = ref(null)

    onMounted(() => {
      fetchTransactions()
    })

    const onSearch = (e) => {
      console.log(e.target.value);
      fetchTransactions(e.target.value)
    }

    const addingKey = (data) => {
      return data.map(el => {
        return {
          ...el,
          key: el._id,
        }
      })
    }

    const fetchTransactions = async (search = '') => {
      loadingTable.value = true
      console.log(props);
      const data = await store.dispatch('transaction/FETCH_TRANSACTION_USER_BY_ADMIN', { id: props.userId, search })
      loadingTable.value = false
      dataTable.value = data.map((el => {
        return {
          ...el,
          key: el._id,
          createdAt: moment(el.createdAt).locale('id').format('dddd, DD MMMM YYYY HH:mm:ss'),
          totalCourse: el.transactionDetails.length,
          transactionDetails: addingKey(el.transactionDetails),
        }
      }))
    }

    const onUpdateStatus = ((record) => {
      console.log(record);
      const xenditMetaData = JSON.parse(record.xenditMetaData)
      store.dispatch('transaction/UPDATE_STATUS_TRANSACTION', { xenditId: xenditMetaData.id })
        .then(res => {
          notification.success({
            message: 'Berhasil Memperbaharui Status Transaksi',
          });
          fetchTransactions()
        })
        .catch(err => {
          notification.error({
            message: 'Gagal Memperbaharui Status Transaksi',
          });
        })
    })

    return {
      search,
      columns,
      dataTable,
      loadingTable,
      innerColumns,
      priceFormat,
      onSearch,
      onUpdateStatus,
    }
  },
}
</script>

<style>
</style>